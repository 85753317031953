import { Grid } from "@material-ui/core";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ReportItem from "../../../components/report-item";
import { convertToInt } from "../../../utils/convert-number";

const SummaryContainer = ({ isLoading, summaryData = {} }) => {
  const {
    assets = 0,
    liabilities = 0,
    netPosition = 0,
    avgMonthly = 0,
    currentYear = 0,
    predictedYear = 0,
  } = summaryData;
  const reportItems = [
    { name: "Total Cash Assets", value: convertToInt(assets) },
    { name: "Total Liabilities", value: convertToInt(liabilities) },
    { name: "Total Net Position", value: convertToInt(netPosition) },
    { name: "Average Monthly Income", value: convertToInt(avgMonthly) },
    { name: "Total Current Year", value: convertToInt(currentYear) },
    { name: "Total Predicted Year", value: convertToInt(predictedYear) },
  ];
  return (
    <ScrollContainer>
      <Grid container className="dashboard-report__container">
        {reportItems.map((item) => (
          <Grid key={item.name} className="dashboard-report__item" item>
            <ReportItem
              isLoading={isLoading}
              name={item.name}
              value={item.value}
            />
          </Grid>
        ))}
      </Grid>
    </ScrollContainer>
  );
};

export default SummaryContainer;
