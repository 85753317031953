import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";

const ReportItem = ({ isLoading, name = " ", value = 0 }) => {
  return (
    <div className="report-items">
      <div className="report-header">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            height={20}
            stype={{ padding: "0.5rem 0" }}
          />
        ) : (
          name
        )}
      </div>
      <div className="report-body">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="text"
            width="60%"
            height={20}
            stype={{ padding: "0.5rem 0" }}
          />
        ) : (
          <NumberFormat
            value={value || 0}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        )}
      </div>
    </div>
  );
};

export default ReportItem;

ReportItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
};
